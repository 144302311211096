<template>
  <div class="page">
    <div class="data-table-container">
      <div class="tools-bar">
        <el-button-group class="i">
          <el-button @click="onCreate" type="primary">新建</el-button>
          <el-button @click="load">刷新</el-button>
          <el-button @click="onExportSelection">导出(所选)</el-button>
          <el-button @click="onExportRange">导出(范围)</el-button>

        </el-button-group>
        <el-select class="i" v-model="filter_type"
                   @change="onChangeType" style="width: 140px">
          <el-option v-for="c in ['课程',  'VIP']" :key="c" :label="c" :value="c"/>
        </el-select>
        <div class="grow"></div>
      </div>

      <el-table class="table"
                v-loading="loading"
                :data="tableData"
                element-loading-text="获取数据中 ..."
                @selection-change="onSelectionChange"
                fit highlight-current-row>
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column align="center" label="ID" width="90" prop="id"/>
        <el-table-column label="兑换码" width="140" prop="code"/>
        <el-table-column label="类型" width="90" prop="type"/>
        <el-table-column label="内容">
          <template slot-scope="scope">
            <div v-if="scope.row.type === 'VIP'">时长： {{ scope.row.duration_days }}天</div>
            <ul v-if="scope.row.type === '课程'">
              <li v-for="c in scope.row.content" :key="c.id">
                <router-link :to="'/course-editor?id=' + c.id">{{ c.title }}</router-link>
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column label="兑换日期" width="120" prop="datetime_used"
                         :formatter="(row) => row.datetime_created.slice(0,10)"/>
        <el-table-column label="截止日期" width="120" prop="datetime_expired"
                         :formatter="(row) => (row.datetime_expired || '').slice(0,10)"/>


        <el-table-column label="使用者" width="150">
          <template slot-scope="scope">
            <xid-link v-if="scope.row.status" :xid="'user-' + scope.row.user.id" :name="scope.row.user.nickname"/>
            <el-tag v-else>未使用</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="mini" @click="() => onOpenDetail(scope.row)">详情</el-button>
              <el-button size="mini" @click="() => handleMiniCode(scope.row)">小程序码</el-button> 
              <el-button size="mini" type="danger" v-if="!scope.row.status"
                         @click="onDelete(scope.row)">删除
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination class="pagination"
                     layout="total, prev, pager, next"
                     @current-change="load"
                     :current-page.sync="page"
                     :page-size="30"
                     :total="total"/>

    </div>

    <el-dialog :title="dialogForm.id? '兑换码详情': '新建兑换码'"
               width="600px" v-loading="dialogLoading"
               :visible.sync="dialogVisible">
      <el-form class="form" ref="form" :model="dialogForm" label-width="100px">
        <div v-if="dialogForm.id">
          <el-form-item label="申请员工">
            {{ (dialogForm.user || {}).realname || $dbus.account.realname }}
          </el-form-item>
          <el-form-item label="券编号">
            {{ dialogForm.code }}
          </el-form-item>
          <el-form-item label="类型">
            {{ dialogForm.type }}
          </el-form-item>
          <el-form-item label="时间" v-if="dialogForm.id">
            <ul class="date-list">
              <li>课程有效期：{{ dialogForm.date_available_range.join(' - ') }}</li>
              <li>创建时间：{{ dialogForm.datetime_created }}</li>
              <li>兑换时间：{{ dialogForm.datetime_used }}</li>
              <li>失效时间：{{ dialogForm.datetime_expired }}</li>
            </ul>
          </el-form-item>
          <el-form-item label="内容">
            <div v-if="dialogForm.type === 'VIP'">时长： {{ dialogForm.duration_days }}天</div>
            <ul v-if="dialogForm.type === '课程'">
              <li v-for="c in dialogForm.content" :key="c.id">
                <router-link :to="c.id">{{ c.title }}</router-link>
              </li>
            </ul>
          </el-form-item>
        </div>

        <div v-else>
          <el-form-item label="类型">
            <el-select class="i" v-model="dialogForm.type">
              <el-option v-for="c in ['课程',  'VIP']" :key="c" :label="c" :value="c"/>
            </el-select>
          </el-form-item>
          <el-form-item label="数量">
            <el-input-number v-model="dialogForm.num"/>
            <div class="tip">选填，可一次性生成多个VIP兑换券</div>
          </el-form-item>
          <el-form-item label="截止使用时间">
            <el-date-picker
                v-model="dialogForm.datetime_expired"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
            <div class="tip">过期之后不可使用，并自动删除</div>
          </el-form-item>

          <el-form-item label="会员有效天数" v-if="dialogForm.type === 'VIP'">
            <el-input-number v-model="dialogForm.duration_days"/>
            天
          </el-form-item>

          <el-form-item label="课程有效时间" v-if="dialogForm.type === '课程'">
            <el-date-picker style="width: 400px"
                            v-model="dialogForm.date_available_range"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="截止日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="兑换课程" v-if="dialogForm.type === '课程'">
            <el-select multiple filterable
                       v-model="dialogForm.content" placeholder="请选择兑换课程">
              <el-option
                  v-for="item in courses"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" :loading="loading">提交</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>


    <el-dialog title="小程序码"
               width="600px"  
               :visible.sync="miniCodeVisible">
               <div style="text-align:center">
                <img style="margin:10px auto" :src="MiniCode" alt="" class="MiniCode"/>
               </div>
    </el-dialog>

  </div>
</template>

<script>
import XidLink from "@/comps/xid-link";
import axios from 'axios'


export default {
  components: { XidLink },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dialogForm: {
        user: {},
        duration_days: 365,
        type: 'VIP'
      },
      filter_type: 'VIP',
      dialogLoading: false,
      inputSearch: '',
      typeSelect: '',
      typeOptions: ['类型1', '类型2'],
      tableData: [],
      page: 1,
      total: 1,
      courses: [],
      selected_rows: [],
      MiniCode:'',
      miniCodeVisible:false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.$api.request('/redeem/list_with_page', {
        page: this.page,
        filter_type: this.filter_type
      }).then(data => {
        this.tableData = data.contains
        this.page = data.page
        this.total = data.total
        this.loading = false
        window.scrollTo(0, 0)
      })

      this.$api.request('/course/list_simple').then(data => {
        this.courses = data.map(r => {
          return {
            id: r.id,
            title: r.title
          }
        })
      })
    },
    onChangeType() {
      this.page = 1
      this.load()
    },
    onSelectionChange(rows) {
      this.selected_rows = rows.map(r => r.id)
    },
    onExportSelection() {
      if (!this.selected_rows.length) {
        return this.$message.error('您必须选择至少一行')
      }


      const m = this.$message({
        message: `正在生成，请耐心等待...约${Math.floor(this.selected_rows.length / 2.5)}秒`,
        iconClass: 'el-icon-loading',
        duration: 0
      })

      axios({
        method: 'post',
        url: `${this.$settings.host}/redeem/export_redeems_zip?ids=${this.selected_rows.join(',')}`,
        data: {},
        responseType: 'blob'
      }).then(({ data }) => {
        m.close()
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([data]))
        link.setAttribute('download', `兑换码.zip`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(link.href)
      }).catch((error) => {
        m.close()
        console.log(error)
      })

    },
    onExportRange() {
      let start = prompt('请输入开始ID')
      let end = prompt('请输入末尾ID')
      if (!(start && end)) {
        return this.$message.error('格式错误')
      }

      const m = this.$message({
        message: `正在生成，请耐心等待...约${Math.floor((end - start) / 2.5)}秒`,
        iconClass: 'el-icon-loading',
        duration: 0
      })

      axios({
        method: 'post',
        url: `${this.$settings.host}/redeem/export_redeems_zip?id_range=${start},${end}`,
        data: {},
        responseType: 'blob'
      }).then(({ data }) => {
        m.close()
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([data]))
        link.setAttribute('download', `兑换码.zip`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(link.href)
      }).catch((error) => {
        m.close()
        console.log(error)
      })
    },
    onSearch() {
      // this.loading = true
      // this.$api.request('/path', {}).then(data => {
      //   this.tableData = data.contains
      //   this.page = data.page
      //   this.total = data.total
      //   this.loading = false
      // })
    },
    onCreate() {
      this.dialogVisible = true
      this.dialogForm = {
        user: {},
        type: 'VIP',
        num: 1,
      }
    },
    onOpenDetail(row) {
      this.dialogVisible = true
      this.dialogForm = row
    },
    onSubmit() {
      this.loading = true
      let form = {
        type: this.dialogForm.type,
        uid: this.$dbus.account.id,
        datetime_expired: this.dialogForm.datetime_expired,
        num: this.dialogForm.num,
        date_available_range: this.dialogForm.date_available_range,
      }

      if (this.dialogForm.type === 'VIP') {
        form.duration_days = this.dialogForm.duration_days
      }
      if (this.dialogForm.type === '课程') {
        form.content = this.dialogForm.content.map(i => {
          let title = this.courses.filter(r => r.id === i)[0].title
          return { id: i, title }
        })
      }

      this.$api.request('/redeem/create', form).then(data => {
        this.loading = false
        this.dialogVisible = false
        this.filter_type = form.type
        this.page = 1
        this.$message.success('创建成功！')
        this.load()
      }).catch(() => {
        this.loading = false
      })

    },
    onDelete({ id }) {
      this.$prompt('请输入管理员密码', {
        inputType: 'password',
        inputValidator: v => v === this.$settings.admin_password,
        inputErrorMessage: '密码错误'
      }).then(({ value }) => {
        this.$api.request('/redeem/remove', { redeem_id: id }).then(data => {
          this.dialogVisible = false
          this.$message.success('删除成功！')
          this.load()
        })
      })
    },

    handleMiniCode(record) {
      let self = this;
      self.MiniCode = window.host2+'/api/index/createVipCodeImage?type='+record.type+'&code='+record.code;
      self.miniCodeVisible = true;
    },
  }
}
</script>
